<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()" style="overflow-y: scroll;     background-color: rgba(0, 0, 0, .8);">
      <div class="modal-container" style="padding: 20px 10px; font-family: 'sofia-pro'; margin-top:140px;width:400px!important; height: 300px; overflow-y: scroll; " @click.stop>
        <div v-if="getModalInfo">
          <div style="z-index: 9997; float: right; ">
            <img @click="hide()" style=" opacity: .6; cursor:pointer;width: 14px; height: 14px;"  :src="$root.CDN_SITE + 'icon/ic_close_black.svg'">
          </div>
          <div style="clear: both; height: 10px;"></div>

          <div style=" padding: 5px; margin-left: 0;">
            <template v-if="loader">
              <loader></loader>
            </template>
            <template v-else>
              <div v-for="like in likes" style="height: 50px; display: flex; align-items: center;">
                <div style="flex: 1 ">
                  <router-link :to="'/'+like.username" @click.native="hide()">
                    <img  :src="like.avatar_url" style="height: 36px; width: 36px; border-radius: 50%;">
                  </router-link>
                </div>

                <router-link :to="'/'+like.username" @click.native="hide()" style="color: rgb(36, 36, 36);  font-size: 16px; flex:11; width: 250px;margin-left: 8px;">
                    <div style="font-size: 15px; font-weight: 500; color: #2d3640; line-height: 10px;">{{like.username}}</div>
                    <span style="font-size: 11px; color: #5e6b79; font-weight: 500;">{{ like.title }}</span>
                </router-link>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>
  import Loader from "../../components/toolbox/loader";

  export default {
    name: "src-pages-modals-getLikedUsers-v1",
    components: {Loader},
    data () {
      return {
        uuid: null,
        likeStatus: null,
        likes: [],
        likeCount: null,
        disableFollowButton: false,
        loader: false
      }
    },
    methods: {

      followAction(like) {
        this.disableFollowButton = true;
        this.api.user.updateFollowStatus(like.u_uuid, like.followStatus.action,this.$store.state.componentMap.page)
        .then(({data}) => {
          let result = data;
          this.disableFollowButton = false;

          if (result.success) {
            like.followStatus = result.data.followStatus;
          } else {
            this.$toasted.global.errorToast({description: result.message});
            this.$router.push(result.data.redirect_url);
          }
        })
        .catch(err => {
          this.disableFollowButton = false;
          this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
        });
      },

      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      }
    },

    computed: {
      getModalInfo() {
        if (!this.likes || !this.likes.length) {
          this.uuid = this.$store.state.activeModal.info.id;
          this.likes = this.$store.state.activeModal.info.likes;
          this.likeCount = this.$store.state.activeModal.info.likeCount;
        }
        return true;
      }
    }
  }

</script>

<style scoped>

</style>
